.p_card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  padding-bottom: 100px;
  box-sizing: border-box;

  &_swiper {
    width: 100vw;
    padding: 24px 0;

    &_item {
      width: 100%;
      position: relative;
      padding: 0 8px;
      border-radius: 8px;
      box-sizing: border-box;
  
      img {
        width: 100%;
        border-radius: 8px;
      }
      p {
        position: absolute;
        left: 18px;
        bottom: 18px;
        font-size: 14px;
        font-weight: 600;
        color: white;
        letter-spacing: 1.4px;
      }
    }
  }
  &_indicator {
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 8px;

    &_item {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: var(--bgColor);
    }
  }
  &_inactivated {
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_title {
      font-size: 32px;
      color: #212121;
      font-weight: 600;
      letter-spacing: -0.96px;
      margin-bottom: 32px;
    }
    &_button {
      width: 100%;
      height: 48px;
      border-radius: 48px;
      margin-top: 4px;
      background-color: #1437FA;
      font-size: 16px;
      font-weight: 600;
      color: white;
    }
    &_tip {
      width: 100%;
      text-align: center;
      margin-top: 16px;
      font-size: 12px;
      line-height: 1.5;
      color: #808080;
    }
  }
  &_activated {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_t1 {
      font-size: 14px;
      color: #808080;
    }
    &_balance {
      font-size: 32px;
      color: #1B1B1B;
      font-weight: 600;
      margin-top: 2px;
    }
    &_menu {
      width: 100vw;
      padding: 24px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      column-gap: 30px;
      border-bottom: 1px solid #E6E6E6;

      &_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 8px;

        img {
          width: 48px;
          height: 48px;
        }
        p {
          font-size: 14px;
          color: #212121;
        }
      }
    }
    &_list {
      width: 100%;
      display: flex;
      flex-direction: column;

      &_head {
        padding: 24px 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      &_warn {
        width: 100%;
        background-color: #FAA805;
        margin-top: -12px;
        margin-bottom: 12px;
        display: flex;
        border-radius: 4px;
        padding: 8px;
        box-sizing: border-box;
        gap: 4px;

        img {
          width: 16px;
          height: 16px;
        }
        p {
          flex: 1;
          font-size: 12px;
          color: white;
        }
      }
      &_t1 {
        font-size: 18px;
        color: #212121;
        font-weight: 600;
      }
      &_t2 {
        font-size: 12px;
        color: #808080;
        padding: 3px 6px;
        border-radius: 4px;
        background-color: #F7F7F7;
      }
      &_item {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        padding: 14px 0;
        box-sizing: border-box;

        &_row {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          p {
            font-size: var(--fonts, 12px);
            color: var(--color, #808080);
            font-weight: var(--fontw, 400);
          }
        }
      }
    }
  }
}